import React, { useState, Component } from 'react';
import moment from 'moment'
import { Navbar, Nav, Form, Button } from 'react-bootstrap';
import { Container, Row, Col, Card, Image, ListGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import '../css/dashboard.css'
library.add(fas)

export default class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            vehicles: [],
            isLoaded: false,
            events: [],
            listDetail: [],
            statusDetail: null,
            limit: 10
        };
        this.handleClick = this.handleClick.bind(this)
        this.handleStatus = this.handleStatus.bind(this)
        this.handleLimit = this.handleLimit.bind(this)
    }

    componentDidMount() {
        this.getVehicles()
        this.interval = setInterval(() => this.getVehicles(), 60000)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    differentTime(time) {
        const now = moment().utc().add(0, 'hours')
        const diff = now.diff(time, 'seconds')
        return diff
    }

    checkOutside(timestamp) {
        const first = moment().utc().add(7, 'hours').format("YYYY-MM-DDT05:00:00")
        const second = moment().utc().add(7, 'hours').format("YYYY-MM-DDT22:00:00")
        const time = moment(timestamp).utc().add(7, 'hours').format("YYYY-MM-DDTHH:mm:ss")
        
        if (time >= first && time <= second) {
            return false;
        }
        else {
            // console.log("")
            // console.log("timestamp ", timestamp)
            // console.log("first ", first)
            // console.log("second ", second)
            // console.log("time ", time)
            return true;
        }
    }

    formatTime(time) {
        return moment(time).utc().add(7, 'hours').format("DD/MM/YYYY HH:mm:ss")
    }

    getVehicles() {
        console.log("fetch at ", moment().utc().add(7, 'hours').format("YYYY-MM-DDTHH:mm:ss"))
        fetch("http://178.128.52.198:4000/vehicles")
            .then(res => res.json())
            .then(
                (result) => {
                    result.sort(function (a, b) {
                        return new Date(b.timestamp) - new Date(a.timestamp);
                    });
                    //console.log(result)
                    this.setState({
                        vehicles: result,
                        isLoaded: true,
                        limit: 10
                    })

                },
                (error) => {
                    console.log("===error==")
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    getEvent() {
        fetch("http://178.128.52.198:4000/events")
            .then(res => res.json())
            .then(
                (result) => {
                    //console.log(result)
                    this.setState({
                        events: result
                    },
                        function () {
                            this.getSpeed()
                        })
                },
                (error) => {
                    console.log("===error==")
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    getSpeed() {
        // console.log("get speed")
        // this.state.vehicles.map((vehicle, i) => (function (){
        //     console.log("get speed 123")
        //     const data = this.state.events.filter(e => e.UnitId === vehicle.unitId).sort((a, b) => new Date(a.LocalTimestamp) - new Date(b.LocalTimestamp));
        //     console.log("=== data from ===" + vehicle.unitId )
        //     console.log(data[0])
        // }));
        this.state.vehicles.forEach((vehicle) => {
            console.log("vehicle")
        })
    }

    handleLimit() {
        //console.log(this.state.vehicles.length);
        this.setState({
            limit: this.state.vehicles.length
        })
    }

    handleStatus(status) {
        this.setState({
            limit: 10
        })

        if (status === "Over Speed") {
            this.setState({
                listDetail: this.state.vehicles.filter(v => v.event !== undefined && this.differentTime(v.localTimestamp) > 600).filter(v => v.event !== null).filter(v => v.event.eventTypeId === 150),
                statusDetail: status
            })
        }
        else if (status === "Harsh Brake") {
            this.setState({
                listDetail: this.state.vehicles.filter(v => v.event !== undefined && this.differentTime(v.localTimestamp) > 600).filter(v => v.event !== null).filter(v => v.event.eventTypeId === 155),
                statusDetail: status
            })
        }
        else if (status === "Acceleration") {
            this.setState({
                listDetail: this.state.vehicles.filter(v => v.event !== undefined).filter(v => v.event !== null).filter(v => v.event.eventTypeId === 153 && this.differentTime(v.localTimestamp) > 600),
                statusDetail: status
            })
        }
        else if (status === "Station") {
            this.setState({
                listDetail: this.state.vehicles.filter(v => v.location != null).filter(v => v.location.indexOf(",") === -1),
                statusDetail: status
            })
        }
        else if (status === "Status") {
            this.setState({
                listDetail: [...this.state.vehicles.filter(v => this.differentTime(v.timestamp) > 86400 || v.timestamp === null), ...this.state.vehicles.filter(v => this.differentTime(v.timestamp) <= 86400)],
                statusDetail: status
            })
        }
        else if (status === "Outside of Time") {
            this.setState({
                listDetail: this.state.vehicles.filter(v => v.status === "running" || v.status === "idle").filter(v => this.checkOutside(v.timestamp) && this.differentTime(v.timestamp) < 600),
                statusDetail: status
            })
        }
    }

    handleClick(status) {
        this.setState({
            limit: 10
        })
        if (status === "Running") {
            this.setState({
                listDetail: this.state.vehicles.filter(v => v.status === "running"),
                statusDetail: status
            })
        }
        else if (status === "Stop") {
            this.setState({
                listDetail: this.state.vehicles.filter(v => v.status === "stop"),
                statusDetail: status
            })
        }
        else if (status === "Idle") {
            this.setState({
                listDetail: this.state.vehicles.filter(v => v.status === "idle"),
                statusDetail: status
            })
        }
        else if (status === "Offline") {
            this.setState({
                listDetail: this.state.vehicles.filter(v => v.status === "offline" || v.status === null),
                statusDetail: status
            })
        }
    }

    getOverSpeed() {
        return this.state.vehicles.filter(v => v.event !== undefined && this.differentTime(v.localTimestamp) > 600).filter(v => v.event !== null).filter(v => v.event.eventTypeId === 150).length
    }

    getAcceleration() {
        return this.state.vehicles.filter(v => v.event !== undefined).filter(v => v.event !== null).filter(v => v.event.eventTypeId === 153 && this.differentTime(v.localTimestamp) > 600).length
    }

    getHarshBrake() {
        return this.state.vehicles.filter(v => v.event !== undefined && this.differentTime(v.localTimestamp) > 600).filter(v => v.event !== null).filter(v => v.event.eventTypeId === 155).length
    }

    getStation() {
        return this.state.vehicles.filter(v => v.location != null).filter(v => v.location.indexOf(",") === -1).length
    }

    getStatus() {
        return this.state.vehicles.filter(v => this.differentTime(v.timestamp) <= 86400).length + "/" +
            this.state.vehicles.filter(v => this.differentTime(v.timestamp) > 86400 || v.timestamp === null).length
    }

    getOutside() {
        return this.state.vehicles.filter(v => v.status === "running" || v.status === "idle").filter(v => this.checkOutside(v.timestamp) && this.differentTime(v.timestamp) < 600).length
    }

    render() {
        const { error, isLoaded, vehicles, listDetail, statusDetail, limit } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>
        } else if (!isLoaded) {
            return <div>Loading...</div>
        } else {

            return (
                <Container fluid>
                    <Row>
                        <Col>
                            <h4 style={{ paddingTop: "20px" }}>
                                <FontAwesomeIcon style={{ marginRight: "15px" }} icon="chart-pie" />
                                Dashboard
                            </h4>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <Row>
                                <Col>
                                    <Card bg="success" text="white" className="mb-2">
                                        <Card.Body>
                                            <Card.Title className="status-value">{vehicles.filter(v => v.status === "running").length}</Card.Title>
                                            <Card.Text className="status-text">
                                                Running
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Header style={{ textAlign: "center" }}>
                                            <a className="more-info" href="#" onClick={() => this.handleClick("Running")}>More info</a>
                                        </Card.Header>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card bg="secondary" text="white" className="mb-2">
                                        <Card.Body>
                                            <Card.Title className="status-value">{vehicles.filter(v => v.status === "stop").length}</Card.Title>
                                            <Card.Text className="status-text">
                                                Stop
                                             </Card.Text>
                                        </Card.Body>
                                        <Card.Header style={{ textAlign: "center" }}>
                                            <a className="more-info" href="#" onClick={() => this.handleClick("Stop")}>More info</a>
                                        </Card.Header>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card bg="warning" text="white" className="mb-2">
                                        <Card.Body>
                                            <Card.Title className="status-value">{vehicles.filter(v => v.status === "idle").length}</Card.Title>
                                            <Card.Text className="status-text">
                                                Idle
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Header style={{ textAlign: "center" }}>
                                            <a className="more-info" href="#" onClick={() => this.handleClick("Idle")}>More info</a>
                                        </Card.Header>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card bg="danger" text="white" className="mb-2">
                                        <Card.Body>
                                            <Card.Title className="status-value">{vehicles.filter(v => v.status === "offline" || v.status === null).length}</Card.Title>
                                            <Card.Text className="status-text">
                                                Offline
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Header style={{ textAlign: "center" }}>
                                            <a className="more-info" href="#" onClick={() => this.handleClick("Offline")}>More info</a>
                                        </Card.Header>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>

                        <Col sm={6}>

                            <Row>
                                <Col md={6}>
                                    <Card bg="light" text="dark" className="mb-2">
                                        <Card.Body className="card-body-event">
                                            <Row>
                                                <Col lg={6}>
                                                    <Card bg="info" text="white" className="mb-2" style={{ height: "100%" }}>
                                                        <Card.Body style={{ textAlign: "center" }}>
                                                            <Image className="group-body-icon" src={'/icon/speed-white-48dp.svg'} />
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col lg={6} className="status-event">
                                                    <h6>Over Speed</h6>
                                                    <h3>
                                                        <a className="value-info" href="#" onClick={() => this.handleStatus("Over Speed")}>{this.getOverSpeed()}</a>
                                                    </h3>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={6}>
                                    <Card bg="light" text="dark" className="mb-2">
                                        <Card.Body className="card-body-event">
                                            <Row>
                                                <Col lg={6}>
                                                    <Card bg="danger" text="white" className="mb-2" style={{ height: "100%" }}>
                                                        <Card.Body style={{ textAlign: "center" }}>
                                                            <Image className="group-body-icon" src={'/icon/harshbrake.svg'} />
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col lg={6} className="status-event">
                                                    <h6>Harsh Brake</h6>
                                                    <h3>
                                                        <a className="value-info" href="#" onClick={() => this.handleStatus("HarshBrake")}>{this.getHarshBrake()}</a>
                                                    </h3>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Card bg="light" text="dark" className="mb-2">
                                        <Card.Body className="card-body-event">
                                            <Row>
                                                <Col lg={6}>
                                                    <Card bg="success" text="white" className="mb-2" style={{ height: "100%" }}>
                                                        <Card.Body style={{ textAlign: "center" }}>
                                                            <Image className="group-body-icon" src={'/icon/stacked_line_chart-white-48dp.svg'} />
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col lg={6} className="status-event">
                                                    <h6>Acceleration</h6>
                                                    <h3>
                                                        <a className="value-info" href="#" onClick={() => this.handleStatus("Acceleration")}>{this.getAcceleration()}</a>
                                                    </h3>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card bg="light" text="dark" className="mb-2">{/* station */}
                                        <Card.Body className="card-body-event">
                                            <Row>
                                                <Col lg={6}>
                                                    <Card bg="secondary" text="white" className="mb-2" style={{ height: "100%" }}>
                                                        <Card.Body style={{ textAlign: "center" }}>
                                                            <Image className="group-body-icon" src={'/icon/pin_drop-white-48dp.svg'} />
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col lg={6} className="status-event">
                                                    <h6>Station</h6>
                                                    <h3>
                                                        <a className="value-info" href="#" onClick={() => this.handleStatus("Station")}>{this.getStation()}</a>
                                                    </h3>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Card bg="light" text="dark" className="mb-2">{/* station */}
                                        <Card.Body className="card-body-event">
                                            <Row>
                                                <Col lg={6}>
                                                    <Card bg="primary" text="white" className="mb-2" style={{ height: "100%" }}>
                                                        <Card.Body style={{ textAlign: "center" }}>
                                                            <Image className="group-body-icon" src={'/icon/pin_drop-white-48dp.svg'} />
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col lg={6} className="status-event">
                                                    <h6>Status</h6>
                                                    <h3>
                                                        <a className="value-info" href="#" onClick={() => this.handleStatus("Status")}>{this.getStatus()}</a>
                                                    </h3>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card bg="light" text="dark" className="mb-2">
                                        <Card.Body className="card-body-event">
                                            <Row>
                                                <Col lg={6}>
                                                    <Card bg="warning" text="white" className="mb-2" style={{ height: "100%" }}>
                                                        <Card.Body style={{ textAlign: "center" }}>
                                                            <Image className="group-body-icon" src={'/icon/access_time-white-48dp.svg'} />
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col lg={6} className="status-event">
                                                    <h6>Outside of Time</h6>
                                                    <h3>
                                                        <a className="value-info" href="#" onClick={() => this.handleStatus("Outside of Time")}>{this.getOutside()}</a>
                                                    </h3>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>



                        </Col>
                    </Row>
                    {listDetail.length > 0 ?
                        <>
                            <Row style={{ paddingTop: "30px" }}>
                                <Col md={{ span: 10, offset: 1 }}>
                                    <span className="head-detail">{statusDetail + " vehicles"}</span>
                                    <a target="_blank" className="link-detail font-sm" href={"/mapdashboard?type=" + statusDetail.toLowerCase().replaceAll(" ", "")} size="sm" variant="outline-secondary">View on map</a>{' '}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={{ span: 10, offset: 1 }}>

                                    {statusDetail === "Status" ?
                                        <ListGroup variant="flush" >
                                            {listDetail.map((vehicle, i) => (
                                                (i < limit) ?
                                                    (this.differentTime(vehicle.timestamp) > 86400 || vehicle.timestamp === null) ?
                                                        <ListGroup.Item className="font-sm" key={i} style={{ color: "gray" }}>{vehicle.description + " (" + vehicle.unitId + ") offline (24 hours)"}</ListGroup.Item> :
                                                        <ListGroup.Item className="font-sm" key={i}>{vehicle.description + " (" + vehicle.unitId + ")"}</ListGroup.Item>
                                                    : null
                                            ))}
                                        </ListGroup>
                                        :
                                        <ListGroup variant="flush" >
                                            {listDetail.map((vehicle, i) => (
                                                (i < limit) ?
                                                    <ListGroup.Item className="font-sm" key={i}>{vehicle.description + " (" + vehicle.unitId + ")"}</ListGroup.Item>
                                                    : null
                                            ))}
                                        </ListGroup>
                                    }

                                </Col>
                            </Row>
                            <Row>
                                <Col md={{ span: 10, offset: 1 }}>
                                    {listDetail.length > limit ?
                                        <Button style={{ float: "right" }} size="sm" variant="outline-secondary" onClick={() => this.handleLimit()}>All vehicles</Button> : null
                                    }
                                    <br />&nbsp;
                                </Col>
                            </Row>
                        </> : null}
                </Container>

            )
        }
    }
}

