import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./context/auth";

function PrivateRoute({ component: Component, ...rest }) {
  const { authTokens } = useAuth();
  if (authTokens === null) {
    return (
      <Route><Redirect to="/login" /></Route>
    );
  }
  else {
    return (
      <Route
        {...rest}
        render={props =>
          authTokens !== null ? (
            <Component {...props} />
          ) : (
              <Redirect to="/login" />
            )
        }
      />

    );
  }
}

export default PrivateRoute;