import React, { Component } from 'react'
import { Map, TileLayer, Marker, Popup, Circle, LayersControl, LayerGroup, Tooltip } from 'react-leaflet'
import L from 'leaflet'
import { Container, Row, Col, Accordion, Card, Form, Button, Image, DropdownButton, Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import Control from 'react-leaflet-control'
import moment from 'moment'

library.add(fas)

export default class MapDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            markers: [],
            bounds: null,
            mapCenter: null,
            unitId: null,
            speed: null,
            status: null,
            type: null
        };
    }

    componentDidMount() {
        this.setState({
            type: this.getQueryVariable('type')
        }, this.getVehicles())

        this.interval = setInterval(() => this.getVehicles(), 60000);
    }

    getVehicles() {
        fetch("http://178.128.52.198:4000/vehicles")
            .then(res => res.json())
            .then(
                (result) => {

                    if (this.state.type === "running" || this.state.type === "stop" || this.state.type === "idle" || this.state.type === "offline") {
                        result = result.filter(v => v.status === this.state.type)
                    }
                    else if (this.state.type === "overspeed") {
                        result = result.filter(v => v.event !== undefined && this.differentTime(v.localTimestamp) > 600).filter(v => v.event !== null).filter(v => v.event.eventTypeId === 150)
                    }
                    else if (this.state.type === "acceleration") {
                        result = result.filter(v => v.event !== undefined).filter(v => v.event !== null).filter(v => v.event.eventTypeId === 153 && this.differentTime(v.localTimestamp) > 600)
                    }
                    else if (this.state.type === "harshbrake") {
                        result = result.filter(v => v.event !== undefined && this.differentTime(v.localTimestamp) > 600).filter(v => v.event !== null).filter(v => v.event.eventTypeId === 155)
                    }
                    else if (this.state.type === "station") {
                        result = result.filter(v => v.location != null).filter(v => v.location.indexOf(",") === -1)
                    }
                    else if (this.state.type === "status") {
                        result = result
                    }
                    else if (this.state.type === "offline") {
                        result = result.filter(v => v.status === this.state.type || v.status === null)
                    }
                    else if (this.state.type === "outsideoftime") {
                        result = result.filter(v => v.status === "running" || v.status === "idle").filter(v => this.checkOutside(v.timestamp) && this.differentTime(v.timestamp) < 600)
                    }

                    //console.log(result)

                    this.setState({
                        vehicles: result,
                        isLoaded: true,
                        bounds: result.filter(vehicle => vehicle.position !== undefined).map((v) => [v.position[1], v.position[0]])
                    })

                },
                (error) => {
                    console.log("===error==")
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    getEvent() {
        fetch("http://178.128.52.198:4000/events")
            .then(res => res.json())
            .then(
                (result) => {
                    //console.log(result)
                    this.setState({
                        events: result
                    })
                },
                (error) => {
                    console.log("===error==")
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        //console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        //console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            //console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    }

    getStatusName(id) {
        if (id === 153) {
            return "Acceleration";
        }
        else if (id === 150) {
            return "Overspeed";
        }
        else if (id === 155) {
            return "Harsh Brake";
        }
        else if (id === 122) {
            return "Outside of Time";
        }
        return "";
    }

    differentTime(time) {
        const now = moment()
        const diff = now.diff(time, 'seconds')
        return diff
    }

    checkOutside(timestamp) {
        const first = moment().utc().add(7, 'hours').format("YYYY-MM-DDT05:00:00")
        const second = moment().utc().add(7, 'hours').format("YYYY-MM-DDT22:00:00")
        const time = moment(timestamp).utc().add(7, 'hours').format("YYYY-MM-DDTHH:mm:ss")
        
        if (time >= first && time <= second) {
            return false;
        }
        else {
            return true;
        }
    }

    render() {
        const { zoom, speed, unitId, status, bounds, type } = this.state;
        const { error, isLoaded, vehicles } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>
        } else if (!isLoaded) {
            return <div>Loading...</div>
        } else {

            return (

                <div style={{ width: "100%", height: "100%", position: "fixed" }}>
                    {vehicles.length > 0 ?
                        <Map zoom={zoom} bounds={bounds} style={{ width: "100%", height: "100%" }}>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
                            />

                            {vehicles.map((vehicle, i) => (
                                <Marker key={i} position={[vehicle.position[1], vehicle.position[0]]}>
                                    {type === "overspeed" || type === "harshbrake" || type === "acceleration" ?
                                        <Tooltip permanent direction={'top'} offset={L.point(0, -15)}>
                                            Description {vehicle.description}<br />
                                            UnitId {vehicle.unitId}<br />
                                            Event {vehicle.status}<br />
                                            Speed {vehicle.speed}
                                        </Tooltip>
                                        :
                                        <Tooltip permanent direction={'top'} offset={L.point(0, -15)}>
                                            Description {vehicle.description}<br />
                                            UnitId {vehicle.unitId}<br />
                                            Status {vehicle.status}<br />
                                            Speed {vehicle.speed}
                                        </Tooltip>}
                                </Marker>
                            ))}


                            <LayersControl position="topright">
                                <LayersControl.BaseLayer checked name="Mapnik">
                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                </LayersControl.BaseLayer>
                                <LayersControl.BaseLayer name="BlackAndWhite">
                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                                    />
                                </LayersControl.BaseLayer>
                            </LayersControl>

                        </Map>
                        : null}
                </div>

            )
        }

    }
}