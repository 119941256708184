import React, { Component, useState } from 'react'
import { Container, Row, Col, Accordion, Card, Form, Button, Image, InputGroup, FormControl, Alert } from 'react-bootstrap'
import { useAuth } from "../context/auth";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

function Login() {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isError, setIsError] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { setAuthTokens } = useAuth();

    function postLogin() {
        if (username == "admin" && password == "2020") {
            setIsError(false);
            setAuthTokens({ username: "developer" });
            setLoggedIn(true);
        }
        else {
            setIsError(true);
        }
    }

    if (isLoggedIn) {
        return <Redirect to="/" />;
    }

    return (
        <Container style={{ paddingTop: '100px' }}>
            <Row>
                <Col md={{ span: 6, offset: 3 }} style={{ textAlign: "center" }}>
                    <h2>Dashboard</h2>
                    <div style={{ color: "gray" }}>Welcome back! Please login to your account.</div>
                    <br />
                </Col>
            </Row>

            {isError === true ?
            <Row>
                <Col md={{ span: 6, offset: 3 }}>
                    <Alert variant={"danger"}>Username or Password is invalid.</Alert>
                </Col>
            </Row>
            : null}
            <Row>
                <Col md={{ span: 6, offset: 3 }}>
                    <Form>

                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FontAwesomeIcon style={{ width: "16px", height: "16px" }} icon="user" color={"#909497"} />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control type="username"
                                value={username}
                                onChange={e => {
                                    setUsername(e.target.value);
                                }} placeholder="Username" />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                    <FontAwesomeIcon style={{ width: "16px", height: "16px" }} icon="key" color={"#909497"} />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control type="password"
                                value={password}
                                onChange={e => {
                                    setPassword(e.target.value);
                                }} placeholder="Password" />
                        </InputGroup>

                        <Button variant="primary" onClick={postLogin} block>
                            Login
                            </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default Login;
