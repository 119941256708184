import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Navbar, Nav, Form, Button } from 'react-bootstrap';
import { Container, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import Login from './components/login'
import Report from './components/report'
import Dashboard from './components/dashboard'
import MapDetail from './components/map-detail'
import MapDashboard from './components/map-dashboard'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import { AuthContext } from "./context/auth";
import PrivateRoute from './PrivateRoute';

library.add(fas)

function App() {

  const existingTokens = JSON.parse(localStorage.getItem('devtokens'));
  const [authTokens, setAuthTokens] = useState(existingTokens);

  const setTokens = (data) => {
    localStorage.setItem("devtokens", JSON.stringify(data));
    setAuthTokens(data);
  }

  function logOut() {
    setAuthTokens(null);
    localStorage.setItem("devtokens", null);
  }

  return (

    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <Router>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <PrivateRoute path="/mapdashboard">
            <div className="Main">
              <header>
                <Navbar bg="light" expand="md">
                  <Navbar.Brand href="/">App</Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                      <Nav.Link href="/">Dashboard</Nav.Link>
                      <Nav.Link href="/report">Report</Nav.Link>
                    </Nav>
                    <Form inline>
                      <Button variant="outline-primary" size="sm" onClick={logOut}>Logout</Button>
                    </Form>
                  </Navbar.Collapse>
                </Navbar>
              </header>
              {/*  */}
              <MapDashboard />
            </div>
          </PrivateRoute>
          <PrivateRoute path="/map">
            <div className="Main">
              <header>
                <Navbar bg="light" expand="md">
                  <Navbar.Brand href="/">App</Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                      <Nav.Link href="/">Dashboard</Nav.Link>
                      <Nav.Link href="/report">Report</Nav.Link>
                    </Nav>
                    <Form inline>
                      <Button variant="outline-primary" size="sm" onClick={logOut}>Logout</Button>
                    </Form>
                  </Navbar.Collapse>
                </Navbar>
              </header>
              {/*  */}
              <MapDetail />
            </div>
          </PrivateRoute>
          <PrivateRoute path="/report">
            <div className="Main">
              <header>
                <Navbar bg="light" expand="md">
                  <Navbar.Brand href="/">App</Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                      <Nav.Link href="/">Dashboard</Nav.Link>
                      <Nav.Link href="/report">Report</Nav.Link>
                    </Nav>
                    <Form inline>
                      <Button variant="outline-primary" size="sm" onClick={logOut}>Logout</Button>
                    </Form>
                  </Navbar.Collapse>
                </Navbar>
              </header>
              {/*  */}
              <Report />
            </div>
          </PrivateRoute>

          <PrivateRoute path="/">
            <div className="Main">
              <header>
                <Navbar bg="light" expand="md">
                  <Navbar.Brand href="/">App</Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                      <Nav.Link href="/">Dashboard</Nav.Link>
                      <Nav.Link href="/report">Report</Nav.Link>
                    </Nav>
                    <Form inline>
                      <Button variant="outline-primary" size="sm" onClick={logOut}>Logout</Button>
                    </Form>
                  </Navbar.Collapse>
                </Navbar>
              </header>
              {/*  */}
              <Dashboard />
            </div>
          </PrivateRoute>

        </Switch>
      </Router>
    </AuthContext.Provider >
  );
}

export default App;
