import React, { Component } from 'react'
import { Map, TileLayer, Marker, Popup, Circle, LayersControl, LayerGroup, Tooltip } from 'react-leaflet'
import L from 'leaflet'
import { Container, Row, Col, Accordion, Card, Form, Button, Image, DropdownButton, Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import Control from 'react-leaflet-control'

library.add(fas)

export default class MapDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lat: 15.7013934,
            lng: 101.4531296,
            zoom: 10,
            markers: [],
            bounds: null,
            mapCenter: null,
            unitId: null,
            speed: null,
            status: null
        };
    }

    componentDidMount() {
        //this.getQueryVariable('lat')
        this.setState({
            lat: this.getQueryVariable('lat'),
            lng: this.getQueryVariable('lng'),
            speed: this.getQueryVariable('speed'),
            unitId: this.getQueryVariable('unitid'),
            status: decodeURI(this.getQueryVariable('status'))
        })
    }

    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    }

    render() {
        const { lat, lng, zoom, speed, unitId, status } = this.state;


        return (
            <div style={{ width: "100%", height: "100%", position: "fixed" }}>
                <Map zoom={zoom} center={[lat, lng]} style={{ width: "100%", height: "100%" }}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
                    />

                    <Marker position={[lat, lng]}>
                        <Tooltip permanent direction={'top'} offset={L.point(0, -15)}>
                            UnitId {unitId}
                            <br/>
                            Status {status}
                            <br/>
                            Speed {speed}
                            <br/>
                            Location
                        </Tooltip>
                    </Marker>

                    <LayersControl position="topright">
                        <LayersControl.BaseLayer checked name="Mapnik">
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name="BlackAndWhite">
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                            />
                        </LayersControl.BaseLayer>
                    </LayersControl>

                </Map>
            </div>
        )

    }
}