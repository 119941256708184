import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Container, Row, Col, Accordion, Card, Form, Button, Image, Table, Badge } from 'react-bootstrap'
import moment from 'moment'
import ReactTable from 'react-table-6'
import '../react-table.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import Workbook from 'react-excel-workbook'
import { isPointInPolygon } from 'geolib'

library.add(fas)

export default class Report extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            events: [],
            reports: [],
            vehicles: [],
            places: [],
            groups: [],
            isLoaded: false,
            valueUnitId: "Description",
            valueStatus: 0,
            //valueGroup: "9de332cc-b0f4-4765-87c9-61de4c50523d",
            valueGroup: "Group",
            start: moment().utc().add(7, 'hours').format("YYYY-MM-DDT00:00"),
            end: moment().utc().add(7, 'hours').format("YYYY-MM-DDTHH:mm"),
            type: 0
        };
        this.handleInputChangeGroup = this.handleInputChangeGroup.bind(this)
        this.handleInputChangeUnitId = this.handleInputChangeUnitId.bind(this)
        this.handleInputChangeStatus = this.handleInputChangeStatus.bind(this)
        this.handleInputChangeStart = this.handleInputChangeStart.bind(this)
        this.handleInputChangeEnd = this.handleInputChangeEnd.bind(this)
        this.handleClick = this.handleClick.bind(this)
    }

    componentDidMount() {

        this.getPlaces()
        this.getGroups()
    }

    polygonName(point, event) {
        var location = "";
        this.state.places.forEach(item => {
            var result = isPointInPolygon(point, item.points)
            if (result === true) {
                console.log("des", item.description)
                location = item.description
            }
        })
        console.log(point)
        console.log(location)
        return location
    }

    formatTime(time) {
        return moment(time).utc().add(7, 'hours').format("DD/MM/YYYY HH:mm:ss")
    }

    updateAddress() {

        this.state.events.forEach(element => {
            if (element.EventTypeId === 109) {
                var requestUrl = "http://178.128.52.198:4000/lastevent?" + "unitid=" + element.UnitId
                    + "&eventtypeid=" + "108"
                    + "&start=" + moment(element.LocalTimestamp).utc().add(7, 'hours').format("YYYY-MM-DDTHH:mm:ss")
                fetch(requestUrl)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            var index = this.state.events.findIndex(x => x.Id === element.Id)
                            const { events } = this.state
                            events[index].GeoName = this.polygonName({ latitude: result.Position[0], longitude: result.Position[1] }, result)
                            console.log("exit at ", result)
                            console.log("name ", this.polygonName({ latitude: result.Position[0], longitude: result.Position[1] }, result))
                            this.setState({
                                events
                            });

                            var indexR = this.state.reports.findIndex(x => x.Id === element.Id)
                            const { reports } = this.state
                            reports[indexR].GeoName = this.polygonName({ latitude: result.Position[0], longitude: result.Position[1] }, result)
                            // console.log("exit at ", result)
                            // console.log("name ", this.polygonName({ latitude: result.Position[0], longitude: result.Position[1] }, result))
                            this.setState({
                                reports
                            });

                        })
            }
        });

        // var requestUrl = "http://localhost:4000/lastevent?" + "unitid=" + unitId
        //     //var requestUrl = "http://178.128.52.198:4000/events?" + "unitid=" + this.state.valueUnitId
        //     + "&eventtypeid=" + eventTypeId
        //     + "&start=" + start

        // console.log("request event ", requestUrl)

        // const response = await fetch(requestUrl, {})
        // const json = await response.json();

        // console.log("json event ", json)
        // return json

    }

    getEvents() {
        var type = null
        var ids = ""
        if ((this.state.valueUnitId !== "Description") && (this.state.valueStatus === 0)) {
            type = 1
        } else if ((this.state.valueUnitId === "Description") && (this.state.valueStatus !== 0)) {
            if (this.state.valueGroup === "Group") {
                type = 2
            } else {
                type = 4
                //var vehicles = []
                var vehicles = this.state.groups.filter(x => x.Id === this.state.valueGroup)[0].Vehicles.map(v => this.state.vehicles.filter(x => x.description === v.Description)[0].unitId)
                if (vehicles.length > 0) {
                    ids = vehicles.join()
                }
            }
        } else if ((this.state.valueUnitId !== "Description") && (this.state.valueStatus !== 0)) {
            type = 3
        }

        if (type !== null) {
            var requestUrl = "http://178.128.52.198:4000/events?" + "unitid=" + this.state.valueUnitId
                + "&eventtypeid=" + this.state.valueStatus
                + "&start=" + this.state.start
                + "&end=" + this.state.end
                + "&type=" + type
                + "&ids=" + ids

            console.log(requestUrl)

            fetch(requestUrl)
                .then(res => res.json())
                .then(
                    (result) => {
                        //console.log(result.filter(event => event.EventTypeId == 108 || event.EventTypeId == 109 || event.EventTypeId == 153 || event.EventTypeId == 143 || event.EventTypeId == 150 || event.EventTypeId == 155))
                        //console.log(result)
                        result = result.map((res) => {
                            return {
                                ...res,
                                Description: this.state.vehicles.filter(v => v.unitId === res.UnitId)[0].description,
                                GeoName: res.EventTypeId !== 109 ? this.polygonName({ latitude: res.Position[0], longitude: res.Position[1] }) : ""
                            }
                        })

                        var custom = result.map((res) => {
                            console.log(res)
                            return {
                                Id: res.Id,
                                DateTime: res.LocalTimestamp,
                                LocalTimestamp: this.formatTime(res.LocalTimestamp),
                                EventTypeDescription: res.EventTypeDescription,
                                Status: this.getStatusName(res.EventTypeId),
                                UnitId: res.UnitId,
                                Description: this.state.vehicles.filter(v => v.unitId === res.UnitId)[0].description,
                                Speed: res.Speed,
                                Address: this.polygonName({ latitude: res.Position[0], longitude: res.Position[1] }, res),
                                Position: res.Position[1] + ", " + res.Position[0],
                                GeoName: res.EventTypeId !== 109 ? this.polygonName({ latitude: res.Position[0], longitude: res.Position[1] }) : ""
                            }
                        })

                        console.log(custom)

                        this.setState({
                            reports: custom,
                            events: result.filter(event => event.EventTypeId == 108 || event.EventTypeId == 109 || event.EventTypeId == 153 || event.EventTypeId == 143 || event.EventTypeId == 150 || event.EventTypeId == 155 || event.EventTypeId == 122).sort((a, b) => new Date(b.LocalTimestamp) - new Date(a.LocalTimestamp)),
                            isLoaded: true,
                            type: type
                        }, function () {
                            this.updateAddress()
                        })
                    },
                    (error) => {
                        console.log("===error==")
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )

        }
    }

    getVehicles() {
        fetch("http://178.128.52.198:4000/vehicles")
            .then(res => res.json())
            .then(
                (result) => {
                    result.sort(function (a, b) {
                        return (a.description).localeCompare(b.description);
                    });
                    console.log("vehicle ", result)
                    this.setState({
                        vehicles: result,
                        isLoaded: true
                    })
                },
                (error) => {
                    console.log("===error==")
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    getPlaces() {
        fetch("http://178.128.52.198:4000/places")
            .then(res => res.json())
            .then(
                (result) => {

                    var polygons = []
                    result.forEach(element => {
                        var points = []
                        var polygon = element.geometry.substring(10, element.geometry.length - 2).split(',')
                        polygon.forEach(item => {
                            points.push(
                                {
                                    latitude: item.replace(/^ /, '').split(' ')[0],
                                    longitude: item.replace(/^ /, '').split(' ')[1]
                                }
                            )
                        })
                        element.points = points
                    });

                    console.log("place ", result)

                    this.setState({
                        places: result
                    })
                },
                (error) => {
                    console.log("===error==")
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    getGroups() {
        fetch("http://178.128.52.198:4000/groups")
            .then(res => res.json())
            .then(
                (result) => {

                    console.log("groups ", result)
                    this.setState({
                        groups: result,
                    }, function () {
                        this.getVehicles()
                    })
                },
                (error) => {
                    console.log("===error==")
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    getStatusName(id) {
        if (id === 108) {
            return "Geofence Entry";
        }
        else if (id === 109) {
            return "Geofence Exit";
        }
        else if (id === 153) {
            return "Acceleration";
        }
        else if (id === 143) {
            return "Idle";
        }
        else if (id === 150) {
            return "Overspeed";
        }
        else if (id === 155) {
            return "Harsh Brake";
        }
        else if (id === 122) {
            return "Outside of Time";
        }
        return "";
    }

    handleInputChangeGroup(event) {
        console.log(event.target.value)
        this.setState({
            [event.target.name]: event.target.value,
            valueGroup: event.target.value
        });
    }

    handleInputChangeUnitId(event) {
        console.log(event.target.value)
        this.setState({
            [event.target.name]: event.target.value,
            valueUnitId: event.target.value
        });
    }

    handleInputChangeStatus(event) {
        this.setState({
            [event.target.name]: event.target.value,
            valueStatus: Number(event.target.value)
        });
    }

    handleInputChangeStart(event) {
        console.log(event.target.value)
        this.setState({
            [event.target.name]: event.target.value,
            start: event.target.value
        });
    }

    handleInputChangeEnd(event) {
        console.log(event.target.value)
        this.setState({
            [event.target.name]: event.target.value,
            end: event.target.value
        });
    }

    handleClick() {
        this.getEvents();
    }

    render() {
        const { error, isLoaded, events, vehicles, valueUnitId, valueStatus, valueGroup, start, end, reports, type, groups } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>
        } else if (!isLoaded) {
            return <div>Loading...</div>
        } else {

            const type1Columns = [
                {
                    Header: null,
                    maxWidth: 50,
                    Cell: (row) => {
                        return <div>{row.viewIndex + 1}</div>;
                    },
                    className: "center",
                    sortable: false
                },
                {
                    Header: 'Date/Time',
                    accessor: 'LocalTimestamp',
                    minWidth: 170,
                    Cell: props => this.formatTime(props.value),
                    className: "center",
                    render: props => props.value
                },
                {
                    Header: 'Status',
                    accessor: 'EventTypeId',
                    minWidth: 170,
                    className: "center",
                    Cell: props => this.getStatusName(props.value),
                },
                {
                    Header: 'Speed',
                    accessor: 'Speed',
                    minWidth: 170,
                    className: "center",
                    render: props => props.value
                },
                {
                    Header: 'Adddress',
                    minWidth: 170,
                    className: "center",
                    accessor: 'GeoName'
                    // Cell: (row) => {
                    //     var location = this.polygonName({ latitude: row.original.Position[0], longitude: row.original.Position[1] }, row.original)
                    //     return location
                    // }
                },
                {
                    Header: 'Map',
                    maxWidth: 60,
                    className: "center",
                    Cell: (row) => {
                        //console.log(row.original.Position[0]);
                        var link = "/map?lat=" + row.original.Position[1] + "&lng=" + row.original.Position[0] + "&unitid=" + row.original.UnitId + "&speed=" + row.original.Speed + "&status=" + this.getStatusName(row.original.EventTypeId);
                        //console.log(link);
                        return <a href={link} target="_blank"><FontAwesomeIcon style={{ color: "#808080" }} icon="map-marker" /></a>;
                    },
                },
            ]

            const type2Columns = [
                {
                    Header: null,
                    maxWidth: 50,
                    Cell: (row) => {
                        return <div>{row.viewIndex + 1}</div>;
                    },
                    className: "center",
                    sortable: false
                },
                {
                    Header: 'Date/Time',
                    accessor: 'LocalTimestamp',
                    minWidth: 170,
                    Cell: props => this.formatTime(props.value),
                    className: "center",
                    render: props => props.value
                },
                {
                    Header: 'Description',
                    accessor: 'Description',
                    minWidth: 170,
                    className: "center"
                },
                {
                    Header: 'Speed',
                    accessor: 'Speed',
                    minWidth: 170,
                    className: "center",
                    render: props => props.value
                },
                {
                    Header: 'Adddress',
                    minWidth: 170,
                    className: "center",
                    accessor: 'GeoName'
                    // Cell: (row) => {
                    //     var location = this.polygonName({ latitude: row.original.Position[0], longitude: row.original.Position[1] }, row.original)
                    //     return location
                    // }
                },
                {
                    Header: 'Map',
                    maxWidth: 60,
                    className: "center",
                    Cell: (row) => {
                        //console.log(row.original.Position[0]);
                        var link = "/map?lat=" + row.original.Position[1] + "&lng=" + row.original.Position[0] + "&unitid=" + row.original.UnitId + "&speed=" + row.original.Speed + "&status=" + this.getStatusName(row.original.EventTypeId);
                        //console.log(link);
                        return <a href={link} target="_blank"><FontAwesomeIcon style={{ color: "#808080" }} icon="map-marker" /></a>;
                    },
                },
            ]

            const type3Columns = [
                {
                    Header: null,
                    maxWidth: 50,
                    Cell: (row) => {
                        return <div>{row.viewIndex + 5}</div>;
                    },
                    className: "center",
                    sortable: false
                },
                {
                    Header: 'Date/Time',
                    accessor: 'LocalTimestamp',
                    minWidth: 170,
                    Cell: props => this.formatTime(props.value),
                    className: "center",
                    render: props => props.value
                },
                {
                    Header: 'Speed',
                    accessor: 'Speed',
                    minWidth: 170,
                    className: "center",
                    render: props => props.value
                },
                {
                    Header: 'Adddress',
                    minWidth: 170,
                    className: "center",
                    accessor: 'GeoName'
                    // Cell: (row) => {
                    //     var location = this.polygonName({ latitude: row.original.Position[0], longitude: row.original.Position[1] }, row.original)
                    //     return location
                    // }
                },
                {
                    Header: 'Map',
                    maxWidth: 60,
                    className: "center",
                    Cell: (row) => {
                        var link = "/map?lat=" + row.original.Position[1] + "&lng=" + row.original.Position[0] + "&unitid=" + row.original.UnitId + "&speed=" + row.original.Speed + "&status=" + this.getStatusName(row.original.EventTypeId);
                        return <a href={link} target="_blank"><FontAwesomeIcon style={{ color: "#808080" }} icon="map-marker" /></a>;
                    },
                },
            ]

            return (
                <Container fluid>
                    <Row>
                        <Col>
                            <h4 style={{ paddingTop: "20px" }}>
                                <FontAwesomeIcon style={{ marginRight: "15px" }} icon="file-alt" />
                                Report
                            </h4>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{ paddingTop: '20px' }}>
                            <Row>
                                <Col>
                                    <Form.Group><Form.Control type="datetime-local" size="sm" onChange={this.handleInputChangeStart} value={start} /></Form.Group>
                                    <Form.Group><Form.Control type="datetime-local" size="sm" onChange={this.handleInputChangeEnd} value={end} /></Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Control size="sm" as="select" onChange={this.handleInputChangeGroup} value={valueGroup}>
                                            <option value={"Group"}>Group</option>
                                            {groups.map((group, i) => (
                                                <option key={i} value={group.Id}>{group.Description}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control size="sm" as="select" onChange={this.handleInputChangeUnitId} value={valueUnitId}>
                                            <option value={"Description"}>Description</option>
                                            {vehicles.map((vehicle, i) => (
                                                valueGroup === "Group" ? <option key={i} value={vehicle.unitId}>{vehicle.description}</option> :
                                                    groups.filter(x => x.Id === valueGroup)[0].Vehicles.length > 0 ?
                                                        groups.filter(x => x.Id === valueGroup)[0].Vehicles.some(item => item.Description === vehicle.description) ?
                                                            <option key={i} value={vehicle.unitId}>{vehicle.description}</option> : null : null

                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Control size="sm" as="select" onChange={this.handleInputChangeStatus} value={valueStatus}>
                                            <option value={0}>Status</option>
                                            <option value={153}>Acceleration</option>
                                            <option value={150}>Overspeed</option>
                                            <option value={155}>Harsh Brake</option>
                                            <option value={143}>Idle</option>
                                            <option value={108}>Geofence Entry</option>
                                            <option value={109}>Geofence Exit</option>
                                            <option value={122}>Outside of Time</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Button variant="primary" size="sm" block onClick={this.handleClick}>Search</Button>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {type === 1 ?
                                        < ReactTable columns={type1Columns}
                                            data={
                                                events
                                            }

                                            defaultSorted={[
                                                {
                                                    id: "DateTime",
                                                    desc: true
                                                }
                                            ]}
                                            defaultPageSize={15}
                                            className="-striped -highlight"
                                        /> : null
                                    }
                                    {type === 2  || type === 4 ?
                                        < ReactTable columns={type2Columns}
                                            data={
                                                events
                                            }

                                            defaultSorted={[
                                                {
                                                    id: "DateTime",
                                                    desc: true
                                                }
                                            ]}
                                            defaultPageSize={15}
                                            className="-striped -highlight"
                                        /> : null
                                    }
                                    {type === 3 ?
                                        < ReactTable columns={type3Columns}
                                            data={
                                                events
                                            }

                                            defaultSorted={[
                                                {
                                                    id: "DateTime",
                                                    desc: true
                                                }
                                            ]}
                                            defaultPageSize={15}
                                            className="-striped -highlight"
                                        /> : null
                                    }

                                </Col>
                            </Row>

                        </Col>
                    </Row>


                    { type === 1 ?
                        <Row style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                            <Col></Col>
                            <Col style={{ textAlign: "right" }}>Download :</Col>
                            <Col style={{ textAlign: "right" }}>
                                <Workbook filename={valueUnitId + ".xlsx"} element={
                                    <button className="btn btn-sm btn-success" style={{ minWidth: "80px" }}>Excel</button>}>
                                    <Workbook.Sheet data={reports} name="Sheet">
                                        <Workbook.Column label="Date/Time" value="LocalTimestamp" />
                                        <Workbook.Column label="Status" value="Status" />
                                        <Workbook.Column label="Speed" value="Speed" />
                                        <Workbook.Column label="Address" value="GeoName" />
                                        <Workbook.Column label="Position" value="Position" />
                                    </Workbook.Sheet>
                                </Workbook>
                            </Col>
                        </Row>
                        : null
                    }
                    { type === 2  || type === 4 ?
                        <Row style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                            <Col></Col>
                            <Col style={{ textAlign: "right" }}>Download :</Col>
                            <Col style={{ textAlign: "right" }}>
                                <Workbook filename={this.getStatusName(valueStatus) + ".xlsx"} element={
                                    <button className="btn btn-sm btn-success" style={{ minWidth: "80px" }}>Excel</button>}>
                                    <Workbook.Sheet data={reports} name="Sheet">
                                        <Workbook.Column label="Date/Time" value="LocalTimestamp" />
                                        <Workbook.Column label="UnitId" value="UnitId" />
                                        <Workbook.Column label="Speed" value="Speed" />
                                        <Workbook.Column label="Address" value="GeoName" />
                                        <Workbook.Column label="Position" value="Position" />
                                    </Workbook.Sheet>
                                </Workbook>
                            </Col>
                        </Row>
                        : null
                    }
                    { type === 3 ?
                        <Row style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                            <Col></Col>
                            <Col style={{ textAlign: "right" }}>Download :</Col>
                            <Col style={{ textAlign: "right" }}>
                                <Workbook filename={valueUnitId + "-" + this.getStatusName(valueStatus) + ".xlsx"} element={
                                    <button className="btn btn-sm btn-success" style={{ minWidth: "80px" }}>Excel</button>}>
                                    <Workbook.Sheet data={reports} name="Sheet">
                                        <Workbook.Column label="Date/Time" value="LocalTimestamp" />
                                        <Workbook.Column label="Speed" value="Speed" />
                                        <Workbook.Column label="Address" value="GeoName" />
                                        <Workbook.Column label="Position" value="Position" />
                                    </Workbook.Sheet>
                                </Workbook>
                            </Col>
                        </Row>
                        : null
                    }

                </Container>
            )
        }
    }
}

